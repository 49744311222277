.bullets {
  $base-line-height: 1.5em !default;
  $action-color: #477DCA !default;
  $dark-gray: #333 !default;
  $base-font-color: $dark-gray !default;
  $icon-bullet-size: 3.5em;

  @include display(flex);
  @include flex-wrap(wrap);
  margin-bottom: $base-line-height;
  margin: 1em;
  overflow: auto;
  padding: 0;

  .bullet {
    @include flex-basis(20em);
    @include flex-grow(1);
  }

  .bullet-icon {
    @include size($icon-bullet-size);
    border-radius: 50%;
    float: left;
    padding: 0;
  }

  .bullet-content {
    margin-left: $icon-bullet-size * 1.4;
    margin-bottom: 2em;
  }

  h3 {
    border-bottom: 1px solid transparentize($base-font-color, 0.8);
    display: inline-block;
    font-size: $icon-bullet-size / 2.5;
    margin: 0 0 ($icon-bullet-size / 6) 0;
    padding-top: $icon-bullet-size / 7;
  }

  li {
    list-style: none;
  }

  p {
    color: $base-font-color;
    line-height: $base-line-height;
  }

  img {
    border-radius: 50%;
    @include size($icon-bullet-size);
  }
}
