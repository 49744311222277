@use "sass:math";

$header-height: 50px;

body > header {

  background-color: $palette-action;
  border-bottom: solid 4px $palette-dark;
  box-shadow: 0px 0px 15px 0px $dark-gray;

  .container {
    display: flex;
    align-items: center;;
  }

  .main-logo {
    @include margin(1em null);
    width: 25%;

    > span {
      display: block;
      @include retina-image('../logo/logo-200x50', 200px $header-height, 'png', '../logo/logo-400x100');
      @include size(200px $header-height);
      @include hide-text;
    }
  }

  nav {
    width: math.div(8, 12) * 100%;
    line-height: $header-height;
    font-weight: bold;

    li, a {
      color: $light-gray;
    }
  }
}


ul[role="menu"] {
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    flex: 1;
    text-align: center;
  }
}
