.hover-tile-outer {
  $base-border-color: gainsboro !default;
  $base-line-height: 1.5em !default;
  $medium-screen: em(640) !default;
  $hover-tile-height: 30em;
  $base-spacing: 1.5em;

  // background-image: attr(data-image url); // doesn't work yet
  background-color: $palette-light;
  background-position: top;
  background-size: cover;
  border: 1px solid $base-border-color;
  cursor: pointer;
  height: $hover-tile-height;
  margin-bottom: $base-line-height;

  // @include media($medium-screen) {
  //   width: 40%;
  // }

  .hover-tile-container {
    height: $hover-tile-height;
    overflow: hidden;
  }

  .hover-tile-container:hover > .hover-tile {
    @include transform(translate(0, -100%));
  }

  .hover-tile {
    @include transition(all, 0.2s ease-in-out);
    background: inherit;
    color: white;
    height: inherit;
    overflow: hidden;
    padding: $base-spacing;
  }

  .hover-tile-hidden {
    background: transparentize(#000, 0.5);

    p {
      color: transparentize(#fff, 0.3);
      line-height: $base-line-height;
    }

    h4 {
      margin: 0 0 0.5em 0;
    }
  }
}

/*
<div class="hover-tile-outer">
  <div class="hover-tile-container">
    <div class="hover-tile hover-tile-visible"></div>
    <div class="hover-tile hover-tile-hidden">
      <h4>Hidden Copy</h4>
      <p>Lorem ipsum dolor provident eligendi fugiat ad exercitationem sit amet, consectetur adipisicing elit. Unde, provident eligendi.</p>
    </div>
  </div>
</div>
*/
