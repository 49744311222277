body.pdf {

  .container {
    display: flex;
  }

  .page-break {
    page-break-before: always;
  }

  .pull-right {
    float: right;
  }
}
