body.portfolio {

  .grid-container {
    display: flex;
    gap: 2em;
    margin-bottom: $base-spacing;

    // @include media($mobile) {
    //   flex-wrap: wrap;
    //   gap: 0;
    // }
  }

  .grid-item {
    width: 100%;
    flex: 1 1 33%;

    .hover-tile-hidden {
      background: transparentize($palette-dark, 0.05);

      h2 {
        color: $light-gray;
      }
    }

    // @include media($mobile) {
    //   flex-basis: 100%;
    //   margin-bottom: $base-spacing;
    // }
  }
}
