main,
.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

.vertical-center {
  display: flex;
  align-items: center;
}

body {
  * {
    box-sizing: border-box;
  }
}
