@use "sass:math";

body.invoice {

    @media print {
        font-size: math.div(1em, $minor-second);

        th, td {
            padding-left: 0.5em;
            padding-right: 0.5em;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        > footer {
          background: transparent;
          color: $base-font-color;
        }
    }

    header,
    main {
        margin-left: auto;
        margin-right: auto;
        max-width: 52em;
    }

    header {
        background-color: white;
        margin-top: $base-spacing;
        align-items: flex-start;
        border-bottom: none;

        address {
            font-size: math.div(1em, $minor-second);
            width: 50%;

            &:last-child {
                text-align: right;
            }
        }
    }

    h2 {
        margin-top: $base-spacing;
        color: $palette-darkest;
        border-bottom: solid 1px $palette-darkest;
    }

    summary {
        section {
            @include margin($base-spacing null);
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            > h3, > table {
                width: 50%;
            }
        }
    }

    .invoice-meta {
      th {
        padding: 0 20px 0 0;
        text-align: left;
        vertical-align: baseline;
        border: none;
      }
      td {
        padding: 0;
        vertical-align: baseline;
      }
    }

    .invoice-end {
        font-size: math.div(1em, $minor-second);
    }

    table {
        th, td {
            text-align: right;
        }

        th {
            vertical-align: top;
            border-bottom: solid 1px $light-gray;
            padding: .5em;
        }

        td {
            border-bottom: none;
            padding: .5em;
        }
    }

    .highlight {
        background-color: $light-gray;
        padding: 5mm;
        margin: 0 -5mm 0 -5mm;
    }

    span.total {
        @include padding(0.5em);
        background-color: $palette-action;
        color: #FFF;
    }

    .details {
        tr.project-row th:first-child {
            text-align: center;
        }

        td,
        th {
            &:first-child {
                text-align: left;
                width: 40%;
            }
        }
    }
}
