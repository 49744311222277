@use "sass:math";

// Typography
$base-font-family: $helvetica;
$heading-font-family: $base-font-family;

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: math.div($base-spacing, 2);
$base-z-index: 0;

// Colors
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #ddd;

/* Coolors Exported Palette - coolors.co/f1be65-2d728f-8c0d32-3c1518-69140e */

$palette-light: #f1be65; //rgba(241, 190, 101, 100)
$palette-action: #2d728f; //rgba(45, 114, 143, 100)
$palette-action2: #8c0d32; //rgba(140, 13, 50, 100)
$palette-darkest: #3c1518; //rgba(60, 21, 24, 100)
$palette-dark: #69140e; //rgba(105, 20, 14, 100)

// Font Colors
$base-background-color: #FFF;
$base-font-color: $dark-gray;
$action-color: $palette-action;
$light-font-color: #FFF;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);
